import {DefaultFile} from 'components/UploadingFile/UploadingFile'
import {ServicesEnum} from 'constants/services'
import {CompanyItem} from 'pages/Company/CompaniesList/types'
import {ServiceGroupItem} from 'pages/Company/CompanyForm/types'
import {ServiceDictionaryDTO} from 'pages/Company/types'
import {Id, Unit} from 'types'

import {api} from './api'

export interface ActionOutDto {
  id: Id
}

type FilterOption = {
  id: string
  name: string
}

export type GetAdminCompaniesFiltersInDto = {
  employees: (FilterOption & {email: string})[]
  companies: FilterOption[]
}

export type LimitType = {
  name: string
  accruedDays: number
}

export type HolidayAmount = {
  name: string
  accruedDays: number
  daysUnderReview: number
  availableDays: number
  limitTypes?: LimitType[]
}

export interface CompanySettingsItem {
  id: number
  hrServiceType: keyof Record<ServicesEnum, string>
  isActive: boolean
  isLimitedAccess?: boolean | null
  isCheckListEnabled?: boolean | null
  isDismissalRequestEnabled?: boolean | null
  isDocumentSignViaDijaEnabled?: boolean | null
  receivingMethods?: ReceivingMethodEnum[]
  hrIds?: number[]
  name: string
  englishName: string
  sequence: number
  hrGroup?: HrGroupItem | null
}

export interface HrGroupItem {
  id?: number
  name: string
  englishName: string
  sequence: number
  originalName?: string
  originalEnglishName?: string
}

export interface CompanyServicesOutDto {
  isArchived: boolean
  companyId: number
  services: CompanySettingsItem[]
}

export interface CompanyServicesInDto {
  isArchived: boolean
  companyInfo: Unit | null
  logo?: DefaultFile
  services: CompanySettingsItemInDto[]
}

export interface CompanySettingsItemInDto extends ServiceGroupItem {
  id: number
  hrGroup?: HrGroupItem
}

export enum ReceivingMethodEnum {
  OFFICE = 'Office',
  ADDRESS = 'Address',
  DIGITAL = 'Digital',
  E_REFERENCE = 'EReference',
}

export const requestsApi = api.injectEndpoints({
  endpoints: build => ({
    getAdminCompaniesList: build.query({
      query: params => ({
        url: 'workflow/admin/company-configs',
        params,
        method: 'GET',
      }),
      providesTags: result =>
        result?.items
          ? [
              ...result.items.map(({id}: CompanyItem) => ({type: 'admin' as const, id})),
              {
                type: 'admin',
                id: 'LIST',
              },
            ]
          : [{type: 'admin', id: 'LIST'}],
    }),

    getAdminCompaniesFilters: build.query<GetAdminCompaniesFiltersInDto, void>({
      query: () => ({url: 'workflow/admin/company-configs/filter-options', method: 'GET'}),
      providesTags: [{type: 'adminCompaniesFilterOptions'}],
    }),

    getAdminCompanyDetails: build.query<CompanyServicesInDto, {id: Id}>({
      query: ({id}) => ({url: `workflow/admin/company-configs/${id}`, method: 'GET'}),
      providesTags: (result, error, {id}) => [{type: 'admin', id}],
    }),

    getAdminServices: build.query<ServiceDictionaryDTO, void>({
      query: () => ({url: 'workflow/admin/service-configs', method: 'GET'}),
    }),

    updateAdminCompany: build.mutation<void, CompanyServicesOutDto & {id: string}>({
      query: ({id, ...data}) => ({url: `workflow/admin/company-configs/${id}`, method: 'PUT', data}),
      invalidatesTags: (result, error, {id}) => [{type: 'admin', id}, {type: 'adminCompaniesFilterOptions'}],
    }),

    createAdminCompany: build.mutation<{id: Id}, CompanyServicesOutDto>({
      query: data => ({url: 'workflow/admin/company-configs', method: 'POST', data}),
      invalidatesTags: () => [{type: 'admin', id: 'LIST'}, {type: 'adminCompaniesFilterOptions'}],
    }),

    updateCompanyLogo: build.mutation<{id: Id}, {id: Id; data: FormData}>({
      query: ({id, data}) => ({
        url: `workflow/admin/company-configs/${id}/logo`,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
        data,
        formData: true,
      }),
    }),
  }),
})

export const {
  // admin
  useGetAdminCompaniesListQuery,
  useGetAdminCompaniesFiltersQuery,
  useGetAdminCompanyDetailsQuery,
  useUpdateAdminCompanyMutation,
  useCreateAdminCompanyMutation,
  useGetAdminServicesQuery,
  useUpdateCompanyLogoMutation,
} = requestsApi
