import React from 'react'
import {Helmet} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation, useParams} from 'react-router-dom'

import Box from '@mui/material/Box'
import get from 'lodash/get'
import {ContentSpinner} from 'ui-lib'

import adaptCompanySettings from '../adapters/adaptCompanySettings'
import {mapServicesToFormValues} from '../adapters/adaptToFormValues'
import {requestFormDataFileAdapter} from '../adapters/requestFormDataFileAdapter'
import CompanyForm from '../CompanyForm'
import checkEmptyServices from '../utils/checkEmptyServices'
import checkHrsFilled from '../utils/checkHrsFilled'

import {useSnackbar} from 'hooks/useSnackbar'
import {CompanyFormValues, ServiceGroupItem} from 'pages/Company/CompanyForm/types'
import NotFound from 'pages/NotFound'
import routes from 'routes'
import {useGetAdminCompanyDetailsQuery, useGetAdminServicesQuery} from 'store/requests'

import editCompanySettings from './hooks/editCompanySettings'
import updateCompanyLogo from './hooks/updateCompanyLogo'

const EditCompany = () => {
  const {t} = useTranslation()
  const enqueueSnackbar = useSnackbar()
  const history = useHistory()
  const {state: locationState} = useLocation()

  const {
    data: servicesDictionary,
    error: servicesDictionaryError,
    isLoading: isServicesDictionaryLoading,
  } = useGetAdminServicesQuery()

  const {id} = useParams<{id: string}>()

  const [addLogo, {isLoading: isLogoLoading}] = updateCompanyLogo()
  const [editCompany, {isLoading: isCreatingCompany}] = editCompanySettings()

  const {
    data: companyConfig,
    error: companyConfigError,
    isLoading: isCompanyInfoLoading,
  } = useGetAdminCompanyDetailsQuery({id: Number(id)})

  const errorStatus = get(companyConfigError, 'status')

  if (servicesDictionaryError) {
    enqueueSnackbar(t('notifications.admin-services-dictionary-error'), {variant: 'error'})
  }

  const handleSubmit = async (values: CompanyFormValues) => {
    if (checkEmptyServices(values)) {
      enqueueSnackbar(t('notifications.add-services'), {variant: 'error'})
      return
    }

    if (checkHrsFilled(values)) {
      enqueueSnackbar(t('notifications.add-hr-manager'), {variant: 'error'})
      return
    }

    const adaptedToFormDataLogoFile = requestFormDataFileAdapter(values)
    const adaptedValues = adaptCompanySettings(values, true)

    await addLogo(id, adaptedToFormDataLogoFile)
    await editCompany(id, adaptedValues)
  }

  const handleClose = () => {
    const from = get(locationState, 'from')
    if (from) {
      history.goBack()
    } else {
      history.replace(routes.adminCompaniesList)
    }
  }

  if (errorStatus === 404) {
    return <NotFound />
  }

  if (!servicesDictionary || isServicesDictionaryLoading || isCompanyInfoLoading || !companyConfig) {
    return <ContentSpinner isLoading />
  }

  const sortServicesBySequence = (services: ServiceGroupItem[]): ServiceGroupItem[] => {
    return services.sort((a, b) => a.sequence - b.sequence)
  }

  const sortServiceFromGroupBySequence = (item: ServiceGroupItem): void => {
    item?.serviceFromGroup?.sort((a, b) => a.sequence - b.sequence)
    item?.serviceFromGroup?.forEach(sortServiceFromGroupBySequence)
  }

  const sortCompanyServices = (companyForm: CompanyFormValues): CompanyFormValues => {
    const sortedServices = sortServicesBySequence(companyForm.services)

    return {
      ...companyForm,
      services: sortedServices.map(service => {
        const sortedService = {...service}
        sortServiceFromGroupBySequence(sortedService)
        return sortedService
      }),
    }
  }

  const adaptedConfig = mapServicesToFormValues({
    companyInfo: companyConfig.companyInfo,
    services: companyConfig.services,
    isArchived: companyConfig.isArchived,
  })

  const initialValues: CompanyFormValues = companyConfig && sortCompanyServices(adaptedConfig)

  const isLoading = isCreatingCompany || isLogoLoading

  return (
    <ContentSpinner isLoading={isLoading} alphaBackground={0.7}>
      <Box>
        <Helmet title={t('create-company.edit-title')} />
        <CompanyForm
          initialValues={initialValues}
          title={t('create-company.edit-title')}
          onSubmit={handleSubmit}
          onClose={handleClose}
          isEdit
        />
      </Box>
    </ContentSpinner>
  )
}

export default EditCompany
