import {generatePath, useHistory} from 'react-router-dom'

import {t} from 'i18next'
import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useSnackbar} from 'hooks/useSnackbar'
import routes from 'routes'
import {CompanyServicesOutDto, useUpdateAdminCompanyMutation} from 'store/requests'
import {Id} from 'types'

const EditCompanySettings = () => {
  const history = useHistory()
  const handleErrors = useErrors()
  const enqueueSnackbar = useSnackbar()

  const [editCompanySettings, {isLoading}] = useUpdateAdminCompanyMutation()

  const handleCreateCompanySettings = async (id: Id, values: CompanyServicesOutDto) => {
    try {
      await editCompanySettings({...values, id: values.companyId.toString()}).unwrap()
      enqueueSnackbar(t('notifications.admin-company-update-success'), {variant: 'success'})
      history.push(generatePath(routes.adminCompaniesList, {id}))
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors

      handleErrors(errorCode, 'notifications.bad-request')
    }
  }

  return [handleCreateCompanySettings, {isLoading}] as const
}

export default EditCompanySettings
