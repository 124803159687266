import * as Yup from 'yup'

const serviceItemSchema: any = Yup.lazy(() =>
  Yup.object().shape({
    isActive: Yup.boolean().nullable(),
    name: Yup.string().required('validation.required'),
    englishName: Yup.string().required('validation.required'),
    serviceFromGroup: Yup.array().of(serviceItemSchema),
    hrServiceType: Yup.string().max(256).nullable(),
  }),
)

export const validationFormSchema = Yup.object().shape({
  logo: Yup.array().nullable().min(1).required('validation.required'),
  services: Yup.array().of(serviceItemSchema).required('validation.required'),
})
