import React from 'react'
import {useTranslation} from 'react-i18next'

import {CancelOutlined, CheckCircleOutline} from '@mui/icons-material'
import {Box, FormControlLabel} from '@mui/material'
import clsx from 'clsx'
import {FieldArray, Form, Formik} from 'formik'
import union from 'lodash/union'
import {Button, PageHeader, ScrollContainer, Switch, Typography} from 'ui-lib'

import CompanySelectedOption from '../components/DialogSelect/CompanySelectedOption'
import DialogSelectService from '../components/DialogSelectService'
import GroupCard from '../components/GroupCard'
import SideBarMenu from '../components/SideBarMenu'
import {IDialogOption, ServiceItem} from '../types'

import searchApi from 'API/search'
import {UploadingFile} from 'components/UploadingFile/UploadingFile'
import {usePrompt} from 'hooks/useBeforeUnloadPrompt'
import AddButton from 'pages/Company/components/AddButton'
import DialogSelect from 'pages/Company/components/DialogSelect'
import ServiceCard from 'pages/Company/components/ServiceCard'

import {validationFormSchema} from './schema'
import type {CompanyFormValues} from './types'
import useCompanyFormStyles from './styles'

const defaultValues = {
  logo: null,
  isArchived: false,
  companyInfo: null,
  services: [],
}

export interface CompanyFormProps {
  title?: string
  onSubmit: (values: CompanyFormValues) => void
  initialValues?: CompanyFormValues
  onClose: () => void
  isEdit?: boolean
}

const CompanyForm: React.FC<CompanyFormProps> = ({
  title = '',
  onSubmit,
  initialValues = defaultValues,
  onClose,
  isEdit,
}) => {
  const classes = useCompanyFormStyles()
  const {t} = useTranslation()
  const {PromptRender} = usePrompt()

  return (
    <Formik<CompanyFormValues>
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationFormSchema}
      onSubmit={values => onSubmit(values)}
    >
      {({values, isSubmitting, dirty, getFieldProps, setFieldValue, setFieldError, errors, resetForm}) => {
        const isCompanyAdded = values.companyInfo?.id

        const handleChangeIsArchived = (e: React.BaseSyntheticEvent) => {
          setFieldError('isArchived', undefined)
          setFieldValue('isArchived', e.target.checked)
        }

        const handleAddCompany = (value?: IDialogOption[]) => {
          setFieldValue('companyInfo', value?.[0])
        }

        const handleDeleteCompany = () => {
          resetForm({
            values: defaultValues,
          })
          setFieldValue('companyInfo', null)
        }

        return (
          <Box className={classes.sideBarContainer}>
            <PromptRender
              when={dirty && !isSubmitting}
              message={t('router-confirmation.you-are-going-to-leave-company-config')}
            />

            <Box className={classes.stickyWrapper}>
              <SideBarMenu data={values.services} />
            </Box>
            <Form className={classes.form}>
              <>
                <PageHeader title={title}>
                  <Button
                    htmlType="button"
                    startIcon={<CancelOutlined />}
                    type="cart-secondary"
                    onClick={onClose}
                    data-test="cancel-btn"
                    disabled={isSubmitting}
                  >
                    {t('actions.cancel')}
                  </Button>
                  <Button
                    htmlType="submit"
                    startIcon={<CheckCircleOutline />}
                    type="cart-secondary"
                    disabled={!values.companyInfo || !dirty || isSubmitting}
                    data-test="create-btn"
                  >
                    {t('actions.submit')}
                  </Button>
                </PageHeader>

                <ScrollContainer isInAggregator={false}>
                  <Box>
                    <Box className={classes.companySection}>
                      <Box className={clsx(classes.companyContainer, classes.companyInfo)}>
                        <Typography variant="h2">{t('create-company.company-attributes')}</Typography>

                        <Box className={classes.wrapper}>
                          {isCompanyAdded && (
                            <FormControlLabel
                              classes={{label: classes.switchControlLabel, root: classes.myFormControlLabelRoot}}
                              control={
                                <Switch
                                  name="isArchived"
                                  data-test="is-archived"
                                  isUpdating={false}
                                  checked={getFieldProps('isArchived').value}
                                  onChange={handleChangeIsArchived}
                                />
                              }
                              label={t('create-company.is-archived-label')}
                              data-test="is-archived"
                            />
                          )}

                          <DialogSelect
                            selectInfo={{
                              title: t('create-company.company-select-title'),
                              description: t('create-company.company-select-desc'),
                              headerTitle: t('create-company.company-select-header-title'),
                              inputName: '',
                              inputPlaceholder: t('create-company.company-select-input-placeholder'),
                              selectPlaceholder: t('create-company.company-select-name'),
                              request: searchApi.getCompanies,
                            }}
                            onChange={value => handleAddCompany(value)}
                            onDelete={handleDeleteCompany}
                            SelectedOptionComponent={CompanySelectedOption}
                            values={values.companyInfo ? [values.companyInfo] : undefined}
                            disabled={isEdit}
                            disabledHover={!!isCompanyAdded}
                          />

                          {isCompanyAdded && (
                            <UploadingFile
                              isRequired
                              uploadButtonText={t('create-company.file-company')}
                              error={!values.logo?.length && errors.logo ? t('validation.required') : ''}
                              accept=".svg"
                              fileSizeInPx={{width: 200, height: 96}}
                              validateInPxMessage="upload-file.file-size-px-company"
                              withStrongValidateSizeInPx
                            />
                          )}
                        </Box>

                        {!!values?.companyInfo && (
                          <>
                            <Typography variant="h2">{t('create-company.company-services')}</Typography>

                            <Box className={classes.wrapper}>
                              <FieldArray
                                name="services"
                                render={arrayHelpers => {
                                  const selectedServicesIds = values.services
                                    .filter(service => !service.isGroup && Number(service.id))
                                    .map(service => service.id)

                                  const selectedServicesInGroupIds = values?.services
                                    ?.flatMap(service =>
                                      service?.serviceFromGroup?.filter(groupService => Number(groupService?.id)),
                                    )
                                    ?.map(service => service?.id)

                                  const allSelectedServices = union(selectedServicesInGroupIds, selectedServicesIds)

                                  const handleAddService = (serviceValues?: ServiceItem[]) => {
                                    const newValue = serviceValues?.[0]

                                    arrayHelpers.push({
                                      ...newValue,
                                      originalName: newValue?.name,
                                      originalEnglishName: newValue?.englishName,
                                      sequence: values.services.length + 1,
                                    })
                                  }

                                  const handleAddGroup = () => {
                                    const newValue = {
                                      isGroup: true,
                                      name: '',
                                      englishName: '',
                                      originalName: t('create-company.group-name-placeholder'),
                                      sequence: values.services.length + 1,
                                      serviceFromGroup: [],
                                    }

                                    arrayHelpers.push(newValue)
                                  }

                                  const handleDeleteGroup = (index: number) => {
                                    arrayHelpers.remove(index)
                                  }

                                  return (
                                    <Box className={classes.servicesWrapper}>
                                      <Box className={classes.buttonsGrid}>
                                        <Box className={classes.buttonsGridBg} />
                                        <AddButton onAdd={handleAddGroup} name={t('create-company.add-group-btn')} />

                                        <DialogSelectService
                                          selectInfo={{
                                            title: t('create-company.select-service-title'),
                                            description: t('create-company.select-service-desc'),
                                            headerTitle: t('create-company.select-service-header-title'),
                                            inputName: '',
                                            inputPlaceholder: t('create-company.select-service-input-placeholder'),
                                            selectPlaceholder: t('create-company.select-service-name'),
                                          }}
                                          onChange={value => handleAddService(value)}
                                          selectedDisabledIds={allSelectedServices}
                                        />
                                      </Box>

                                      <Box className={classes.cardsWrapper}>
                                        {values.services.map((service, serviceIndex) => {
                                          if (!service.isGroup) {
                                            return (
                                              <ServiceCard
                                                // eslint-disable-next-line
                                                key={serviceIndex}
                                                service={service}
                                                serviceIndex={serviceIndex}
                                                values={values.services}
                                                arrayHelpers={arrayHelpers}
                                              />
                                            )
                                          }

                                          return (
                                            <Box>
                                              <FieldArray
                                                name={`services[${serviceIndex}].serviceFromGroup`}
                                                render={arrayHelpersGroup => {
                                                  const handleAddServiceToGroup = (serviceValues?: ServiceItem[]) => {
                                                    const newValue = serviceValues?.[0]

                                                    arrayHelpersGroup.push({
                                                      ...newValue,
                                                      originalName: newValue?.name,
                                                      originalEnglishName: newValue?.englishName,
                                                      sequence: values.services.length + 1,
                                                      isGroup: true,
                                                    })
                                                  }
                                                  return (
                                                    // eslint-disable-next-line
                                                    <Box className={classes.groupPositionRelative} key={serviceIndex}>
                                                      <GroupCard
                                                        service={service}
                                                        index={serviceIndex}
                                                        onDeleteGroup={handleDeleteGroup}
                                                        values={values.services}
                                                        arrayHelpers={arrayHelpers}
                                                      />

                                                      <Box className={classes.selectBackground}>
                                                        <DialogSelectService
                                                          selectInfo={{
                                                            title: t('create-company.select-service-in-group-title'),
                                                            description: t('create-company.select-service-desc'),
                                                            headerTitle: t(
                                                              'create-company.select-service-header-title',
                                                            ),
                                                            inputName: '',
                                                            inputPlaceholder: t(
                                                              'create-company.select-service-input-placeholder',
                                                            ),
                                                            selectPlaceholder: t(
                                                              'create-company.select-service-in-group-name',
                                                            ),
                                                          }}
                                                          onChange={handleAddServiceToGroup}
                                                          selectedDisabledIds={allSelectedServices}
                                                        />
                                                      </Box>

                                                      <Box className={classes.serviceInGroupWrapper}>
                                                        {service.serviceFromGroup.map(
                                                          (serviceItem, serviceInGroupIndex) => {
                                                            return (
                                                              <ServiceCard
                                                                // eslint-disable-next-line
                                                                key={serviceInGroupIndex}
                                                                service={serviceItem}
                                                                serviceIndex={serviceIndex}
                                                                serviceInGroupIndex={serviceInGroupIndex}
                                                                values={service.serviceFromGroup}
                                                                arrayHelpers={arrayHelpersGroup}
                                                              />
                                                            )
                                                          },
                                                        )}
                                                      </Box>
                                                    </Box>
                                                  )
                                                }}
                                              />
                                            </Box>
                                          )
                                        })}
                                      </Box>
                                    </Box>
                                  )
                                }}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </ScrollContainer>
              </>
            </Form>
          </Box>
        )
      }}
    </Formik>
  )
}

export default CompanyForm
