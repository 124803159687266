import {makeStyles} from '@mui/styles'
import {colors, commonDimensions} from 'ui-lib'

const useSideBarMenuStyles = makeStyles(() => ({
  container: {
    width: 300,
    boxSizing: 'border-box',
    padding: '16px 0',
    backgroundColor: colors.gray100,
    position: 'sticky',
    top: commonDimensions.headerHeight,
    overflowY: 'scroll',
    height: `calc(100vh - ${commonDimensions.headerHeight} - ${commonDimensions.mainBottomMargin} - ${commonDimensions.mainBottomMargin} - ${commonDimensions.borderWidth})`,
  },
  prompt: {
    backgroundColor: colors.white,
    padding: 40,
    margin: 16,
  },
  iconBox: {
    height: 24,
    width: 24,
    marginRight: 8,
    '& img': {
      height: 24,
      width: 24,
    },
  },
  menuItem: {
    padding: '24px 16px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    wordBreak: 'break-word',
  },
  list: {
    backgroundColor: colors.gray100,
  },
  collapseContainer: {
    position: 'relative',
  },
  collapseArrow: {
    position: 'absolute',
    left: 8,
    top: 24,
    cursor: 'pointer',
  },
  collapsedItem: {
    paddingLeft: 40,
    minHeight: 72,
  },
  groupItem: {
    paddingLeft: 24,
  },
  placeholderBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default useSideBarMenuStyles
