import get from 'lodash/get'

import {Errors} from 'constants/errors'
import {useErrors} from 'hooks/useErrors'
import {useUpdateCompanyLogoMutation} from 'store/requests'
import {Id} from 'types'

const UpdateCompanyLogo = () => {
  const handleErrors = useErrors()

  const [updateLogo, {isLoading}] = useUpdateCompanyLogoMutation()

  const handleUpdateLogo = async (id: Id, data: FormData) => {
    try {
      await updateLogo({id, data}).unwrap()
    } catch (error) {
      const errorCode = get(error, 'data.code') as unknown as Errors
      const isErrorCodeValidation = errorCode === Errors.VALIDATION_ERROR

      handleErrors(
        errorCode,
        `notifications.${isErrorCodeValidation ? 'file-for-logo-does-not-fit-size' : 'bad-request'}`,
      )
    }
  }

  return [handleUpdateLogo, {isLoading}] as const
}

export default UpdateCompanyLogo
