import {makeStyles} from '@mui/styles'
import {colors} from 'ui-lib'

const useCompanyFormStyles = makeStyles(() => ({
  formSection: {
    padding: 50,
  },

  companyInfo: {
    minHeight: 344,
  },

  companyContainer: {
    maxWidth: 1150,
    width: '100%',
    margin: '0 auto',
  },

  companySection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '24px 40px',

    '& + &': {
      borderTop: `1px solid ${colors.gray60}`,
    },
  },

  wrapper: {
    maxWidth: 720,
    margin: '0 auto',
  },

  switchControlLabel: {
    marginLeft: 10,
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400,
    color: colors.black,
  },

  myFormControlLabelRoot: {
    marginBottom: 24,
    marginTop: 8,
    paddingLeft: 12,
    minHeight: 48,
  },

  buttonsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 48,
    position: 'sticky',
    top: 0,
    zIndex: 3,
    paddingTop: '24px',
  },

  buttonsGridBg: {
    position: 'absolute',
    right: 0,
    left: -10,
    height: '100%',
    backgroundColor: colors.white,
    width: 'calc(100% + 20px)',
  },

  cardsWrapper: {
    width: '100%',
    display: 'grid',
    rowGap: '32px',
  },

  serviceInGroupWrapper: {
    marginLeft: '35px',
    display: 'grid',
    rowGap: '24px',
  },

  selectBackground: {
    margin: '0 0 8px 35px',
    backgroundColor: colors.gray50,
    padding: '16px',
    position: 'sticky',
    top: 90,
    zIndex: 2,
  },
  servicesWrapper: {
    position: 'relative',
  },
  sideBarContainer: {
    display: 'flex',
    gap: 10,
  },
  stickyWrapper: {
    position: 'relative',
  },
  form: {
    width: '100%',
    boxShadow: '0 2px 4px 0 #ccc',
  },
  groupPositionRelative: {
    position: 'relative',
  },
}))

export default useCompanyFormStyles
