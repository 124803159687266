import React, {Suspense, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import PropTypes from 'prop-types'
import {Preloader} from 'ui-lib'

import {loadUserData} from 'store/account'

import MsalAuth, {ACCESS_TOKEN_NAME} from './MsalAuth'

const msal = new MsalAuth()

const AuthContainer = ({setIsDataLoaded}) => {
  const dispatch = useDispatch()
  const [AuthApp, setAuthApp] = useState(null)

  useEffect(() => {
    const handleLoginErrors = errObj => {
      console.log('login error', errObj)
      setAuthApp(() => React.lazy(() => import('./AuthenticatedApp')))
    }

    // eslint-disable-next-line consistent-return
    const setUserTokenAndRenderApp = async ({accessToken}) => {
      localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)

      try {
        const data = await dispatch(loadUserData())
        if (data.error) return handleLoginErrors(data.payload)

        setAuthApp(() => React.lazy(() => import('./AuthenticatedApp')))
        setIsDataLoaded(true)
      } catch (err) {
        console.error('load user data Error', err)
        msal.logout()
      }
    }

    msal
      .getAccessToken()
      .then(setUserTokenAndRenderApp)
      .catch(({errorCode = null}) => {
        if (msal.requiresInteraction(errorCode)) {
          msal.acquireTokenRedirect()
        } else if (msal.isUserLoginError(errorCode)) {
          msal.redirectToLogin()
        }
      })
  }, [dispatch, setIsDataLoaded])

  return AuthApp
}

const MSAuth = props => {
  const {t} = useTranslation()
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const AuthAction = AuthContainer({setIsDataLoaded})

  return (
    <>
      <Preloader isDataLoaded={isDataLoaded} title={t('loader')} />
      <Suspense fallback="">{AuthAction && <AuthAction>{props.children}</AuthAction>}</Suspense>
    </>
  )
}

MSAuth.propTypes = {
  children: PropTypes.any, // eslint-disable-line
}

export {msal}
export default MSAuth
