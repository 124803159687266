import React, {useState} from 'react'
import {TranslationUA} from 'react-app-env'
import {Normalize, useTranslation} from 'react-i18next'

import {CancelOutlined, CheckCircleOutline} from '@mui/icons-material'
import {Box} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import {Form, Formik} from 'formik'
import get from 'lodash/get'
import {Button, FormSelect as Select, FormTextField as TextField, PageHeader, ScrollContainer, Switch} from 'ui-lib'

import {UploadingFile} from 'components/UploadingFile/UploadingFile'
import {ServicesEnum} from 'constants/services'
import {usePrompt} from 'hooks/useBeforeUnloadPrompt'

import ActiveDirectoryInput from './components/ActiveDirectoryInput'
import {getServiceValidationSchema} from './scheme'
import useServiceFormStyles from './serviceFormStyles'
import {ResourceTypeEnum, ServiceFormValues} from './types'

export const defaultInitialValues: ServiceFormValues = {
  hrServiceType: undefined,
  englishName: '',
  resourceType: ResourceTypeEnum.INTERNAL,
  englishLink: '',
  link: '',
  name: '',
  adGroups: undefined,
  logo: undefined,
  isLimitedByAD: false,
}

const servicesTranslationKeys: Record<string, Normalize<TranslationUA>> = {
  [ServicesEnum.HOLIDAYS]: 'services.holidays',
  [ServicesEnum.DISMISSAL]: 'services.dismissal',
  [ServicesEnum.REFERENCES]: 'services.references',
  [ServicesEnum.PROFILE]: 'services.profile',
}

interface ServiceFormProps {
  onSubmit: (values: ServiceFormValues) => void
  title: string
  goBack: () => void
  initialValues?: ServiceFormValues
}

const ServiceForm: React.FC<ServiceFormProps> = ({onSubmit, title, goBack, initialValues = defaultInitialValues}) => {
  const {t} = useTranslation()
  const classes = useServiceFormStyles()
  const {PromptRender} = usePrompt()

  const [resourceType, setResourceType] = useState<ResourceTypeEnum | undefined>(ResourceTypeEnum.INTERNAL)
  const serviceOptions = Object.keys(servicesTranslationKeys).map(key => ({
    value: key,
    label: t(servicesTranslationKeys[key]),
  }))

  return (
    <Formik<ServiceFormValues>
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={getServiceValidationSchema(resourceType)}
      onSubmit={async values => {
        await onSubmit(values)
      }}
    >
      {({
        dirty,
        isSubmitting,
        setFieldValue,
        setFieldError,
        values,
        setFieldTouched,
        getFieldProps,
        errors,
        resetForm,
      }) => {
        const isInternal = values.resourceType === ResourceTypeEnum.INTERNAL
        const isLimitedByAd = values.isLimitedByAD

        const handleResourceTypeChange = async (e: unknown) => {
          const value = get(e, 'target.value')
          setResourceType(value)
          setFieldValue('resourceType', value)
          setFieldTouched('resourceType', true)
          setFieldError('resourceType', undefined)

          resetForm({
            values: {
              ...values,
              resourceType: value!,
              link: '',
              englishLink: '',
              hrServiceType: value === ResourceTypeEnum.INTERNAL ? null : ServicesEnum.EXTERNAL,
            },
          })
        }

        const handleChangeIsLimitedByAD = (e: React.BaseSyntheticEvent) => {
          setFieldError('isLimitedByAD', undefined)
          setFieldValue('isLimitedByAD', e.target.checked)

          setFieldValue('adGroups', null)
        }

        return (
          <>
            <PromptRender when={dirty && !isSubmitting} message={t('router-confirmation.you-are-going-to-leave')} />
            <Form>
              <PageHeader title={title}>
                <Button
                  htmlType="button"
                  startIcon={<CancelOutlined />}
                  type="cart-secondary"
                  onClick={goBack}
                  data-test="cancel-btn"
                  disabled={isSubmitting}
                >
                  {t('create-service.cancel')}
                </Button>
                <Button
                  htmlType="submit"
                  startIcon={<CheckCircleOutline />}
                  type="cart-secondary"
                  disabled={!dirty || isSubmitting}
                  data-test="create-btn"
                >
                  {t('create-service.save')}
                </Button>
              </PageHeader>
              <ScrollContainer isInAggregator={false}>
                <Box className={classes.formSection}>
                  <Box className={classes.attributeFormSection}>
                    <TextField
                      className={classes.name}
                      name="name"
                      label={t('create-service.name')}
                      inputProps={{maxLength: 256, 'data-test': 'name'}}
                      helperText={t('validation.required')}
                    />

                    <TextField
                      className={classes.englishName}
                      name="englishName"
                      label={t('create-service.english-name')}
                      inputProps={{maxLength: 256, 'data-test': 'english-name'}}
                      helperText={t('validation.required')}
                    />

                    <RadioGroup
                      className={classes.radioGroup}
                      value={values.resourceType}
                      onChange={handleResourceTypeChange}
                      aria-labelledby="radio-buttons-group"
                      name="radio-buttons-group"
                      data-test="types-group"
                    >
                      <FormControlLabel
                        className={classes.radioLabel}
                        value="internal"
                        control={<Radio />}
                        label={t('create-service.btn-internal')}
                        data-test="types-group-all"
                      />
                      <FormControlLabel
                        className={classes.radioLabel}
                        value="external"
                        control={<Radio />}
                        label={t('create-service.btn-external')}
                        data-test="types-group-active"
                      />
                    </RadioGroup>

                    {isInternal ? (
                      <Select
                        inputProps={{'data-test': 'hr-service-type'}}
                        className={classes.hrServiceType}
                        name="hrServiceType"
                        options={serviceOptions}
                        helperText={t('validation.required')}
                        label={t('create-service.hr-service-type')}
                      />
                    ) : (
                      <>
                        <TextField
                          className={classes.link}
                          name="link"
                          label={t('create-service.link')}
                          inputProps={{maxLength: 256, 'data-test': 'link'}}
                        />

                        <TextField
                          className={classes.englishLink}
                          name="englishLink"
                          label={t('create-service.english-link')}
                          inputProps={{maxLength: 256, 'data-test': 'english-link'}}
                        />
                      </>
                    )}

                    <Box className={classes.adField}>
                      <FormControlLabel
                        classes={{label: classes.switchControlLabel}}
                        control={
                          <Switch
                            name="isLimitedByAD"
                            data-test="limited-by-ad-label"
                            isUpdating={false}
                            checked={getFieldProps('isLimitedByAD').value}
                            onChange={handleChangeIsLimitedByAD}
                          />
                        }
                        label={t('create-service.active-directory-switcher')}
                        data-test="limited-by-ad-switch"
                      />

                      {isLimitedByAd && (
                        <ActiveDirectoryInput
                          onChange={value => setFieldValue('adGroups', value)}
                          values={values.adGroups || undefined}
                          error={!values.adGroups?.length && errors.adGroups ? t('validation.required') : ''}
                        />
                      )}
                    </Box>

                    <UploadingFile
                      className={classes.logo}
                      uploadButtonText={t('create-service.file')}
                      error={!values.logo?.length && errors.logo ? t('validation.required') : ''}
                      accept=".svg"
                      validateInPxMessage="upload-file.file-size-px-service"
                      fileSizeInPx={{width: 150, height: 150}}
                    />
                  </Box>
                </Box>
              </ScrollContainer>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

export default ServiceForm
