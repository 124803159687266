import {CompanyFormValues, ServiceGroupItem} from '../CompanyForm/types'

import adaptBase64ToFileObj from 'components/UploadingFile/utils'
import {CompanyServicesInDto, CompanySettingsItemInDto, ReceivingMethodEnum} from 'store/requests'

const adaptServiceForReceivingMethod = (service: CompanySettingsItemInDto) => {
  return {
    ...service,
    isEnableDigitalReceivingMethod: service?.receivingMethods?.some(method => method === ReceivingMethodEnum.DIGITAL),
    isEnableAddressReceivingMethod: service?.receivingMethods?.some(method => method === ReceivingMethodEnum.ADDRESS),
    isEnableEReferenceReceivingMethod: service?.receivingMethods?.some(
      method => method === ReceivingMethodEnum.E_REFERENCE,
    ),
  }
}

export const mapServicesToFormValues = (companyServices: CompanyServicesInDto): CompanyFormValues => {
  const {isArchived, companyInfo, services} = companyServices

  const servicesArray: ServiceGroupItem[] = []
  const groupsMap: Map<number, ServiceGroupItem> = new Map()

  services.forEach(service => {
    if (service.hrGroup) {
      const groupId = service.hrGroup.id || 0
      let group = groupsMap.get(groupId)
      if (!group) {
        group = {
          id: groupId,
          name: service.hrGroup.name,
          englishName: service.hrGroup.englishName,
          sequence: service.hrGroup.sequence,
          serviceFromGroup: [],
          isGroup: true,
        } as unknown as ServiceGroupItem
        groupsMap.set(groupId, group)
      }

      const mutableService = {...adaptServiceForReceivingMethod(service)}
      mutableService.isGroup = true
      group?.serviceFromGroup.push(mutableService as ServiceGroupItem)
    } else {
      const serviceWithReceivingMethods = adaptServiceForReceivingMethod(service)
      servicesArray.push(serviceWithReceivingMethods)
    }
  })

  const serviceGroups = Array.from(groupsMap.values())
  const logo = companyServices?.companyInfo?.logo ? adaptBase64ToFileObj([companyServices?.companyInfo?.logo]) : null

  return {
    isArchived,
    companyInfo,
    logo,
    services: [...servicesArray, ...serviceGroups],
  }
}
